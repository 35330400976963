import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Container, Row, Col, Card, Dropdown } from 'react-bootstrap';

const CustomerCharts = () => {
  const today = new Date();
  const token = localStorage.getItem('token');

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const [currentMonthIndex, setCurrentMonthIndex] = useState(today.getMonth());
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());

  const getDisplayMonths = () => {
    let months = [];
    for (let i = 2; i >= 0; i--) {
      const monthIndex = (currentMonthIndex - i + 12) % 12;
      months.push(monthNames[monthIndex]);
    }
    return months;
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: getDisplayMonths()
      // title: {
      //   text: `Selected Year: ${selectedYear}`
      // }
    },
    colors: ['#008FFB', '#00E396', '#FEB019'], 
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false, 
    },
    // tooltip: {
    //   y: {
    //     formatter: function (val) {
    //       return val.toLocaleString();
    //     }
    //   }
    // },
    tooltip: {
      theme: 'dark', // Ensures the text is white by default
      y: {
        formatter: function (val) {
          return val.toLocaleString(); // Format tooltip values with commas
        }
      },
      style: {
        fontSize: '14px', // Customize the font size
        fontFamily: 'Helvetica, Arial, sans-serif'
      },
      fillSeriesColor: false, // Prevents tooltip text from taking color from the series
      marker: {
        show: false // Hide the marker in the tooltip
      },
      background: '#000', // Background color of tooltip
      borderColor: '#000', // Border color of tooltip
      textStyle: {
        color: '#000' // Text color of the tooltip
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['#ffffff']
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    }
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Retail',
      data: [0, 0, 0]
    },
    {
      name: 'Site',
      data: [0, 0, 0]
    },
    {
      name: 'Design',
      data: [0, 0, 0]
    }
  ]);

  const fetchData = async () => {
    try {
      const monthsToFetch = getDisplayMonths().map(month => monthNames.indexOf(month) + 1);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}Dashbord-Customer-payment-lise`, {
        months: monthsToFetch,
        year: selectedYear
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      });

      const data = response.data.data;

      const seriesData = {
        Retail: [0, 0, 0],
        Site: [0, 0, 0],
        Design: [0, 0, 0]
      };

      data.forEach(item => {
        const monthIndex = monthsToFetch.indexOf(item.month);
        if (monthIndex !== -1 && seriesData.hasOwnProperty(item.customer_type)) {
          seriesData[item.customer_type][monthIndex] = item.total_amount || 0;
        }
      });

      setChartSeries([
        {
          name: 'Retail',
          data: seriesData.Retail
        },
        {
          name: 'Site',
          data: seriesData.Site
        },
        {
          name: 'Design',
          data: seriesData.Design
        }
      ]);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: getDisplayMonths()
        }
      }));

    } catch (error) {
      console.error('Error fetching chart data:', error);
      setChartSeries([
        {
          name: 'Retail',
          data: [0, 0, 0]
        },
        {
          name: 'Site',
          data: [0, 0, 0]
        },
        {
          name: 'Design',
          data: [0, 0, 0]
        }
      ]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentMonthIndex, selectedYear, token]);

  const handleNext = () => {
    if (currentMonthIndex < 11) {
      setCurrentMonthIndex(currentMonthIndex + 1);
    }
  };
  
  const handlePrevious = () => {
    if (currentMonthIndex > 2) {
      setCurrentMonthIndex(currentMonthIndex - 1);
    }
  };

  return (
    <Card className="flex-fill default-cover customer-background">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="card-title mb-0 text-white">Customer Payment</h5>
        <div className="filters">
          <label className='text-white me-2' htmlFor="year">Year:</label>
          <select
            id="year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            {Array.from({ length: 5 }, (_, i) => today.getFullYear() - i).map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </Card.Header>
      <Card.Body className='padding_50' >

        <div className="app">


          <div className="chart-container position-relative">
            <div className="mixed-chart white-text" style={{ width: '100%' }}>
              <Chart className="white-text"
                options={chartOptions}              
                series={chartSeries}
                type="bar"
                height={410}
              />
            </div>

            <div className="month-navigation">
              <button onClick={handlePrevious} className='arrowback'><IoIosArrowBack /></button>

              <button onClick={handleNext} className='arrowforward'><IoIosArrowForward />
              </button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default CustomerCharts;
