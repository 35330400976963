// import React, { useState, useEffect } from 'react';
// import Chart from 'react-apexcharts';
// import axios from 'axios';
// import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
// import { Card } from 'react-bootstrap';

// const ExpenditureChart = () => {
//   const today = new Date();
//   const token = localStorage.getItem('token');

//   const monthNames = [
//     'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December'
//   ];

//   const [currentMonthIndex, setCurrentMonthIndex] = useState(today.getMonth());
//   const [selectedYear, setSelectedYear] = useState(today.getFullYear());

//   const getDisplayMonths = () => {
//     let months = [];
//     for (let i = 2; i >= 0; i--) {
//       const monthIndex = (currentMonthIndex - i + 12) % 12;
//       months.push(monthNames[monthIndex]);
//     }
//     return months;
//   };

//   const [chartOptions, setChartOptions] = useState({
//     chart: {
//       id: 'basic-bar',
//       toolbar: {
//         show: false
//       }
//     },
//     xaxis: {
//       categories: getDisplayMonths(),
//     },
//     colors: ['#008FFB', '#00E396', '#FEB019'], 

//     plotOptions: {
//       bar: {
//         horizontal: false,
//         columnWidth: '60%',
//         endingShape: 'rounded'
//       }
//     },
//     dataLabels: {
//       enabled: false,  
//       formatter: (val) => {
//         // Add commas to the data labels
//         return val.toLocaleString();
//       }
//     },
//     tooltip: {
//       y: {
//         // Format tooltip values with commas
//         formatter: function (val) {
//           return val.toLocaleString();
//         }
//       }
//     },
//     stroke: {
//       show: true,
//       width: 2,
//       colors: ['#ffffff']
//     },
//     legend: {
//       onItemClick: {
//         toggleDataSeries: false
//       },
//       onItemHover: {
//         highlightDataSeries: false
//       }
//     },
//     grid: {
//       padding: {
//         left: 0,
//         right: 0
//       }
//     }
//   });

//   const [chartSeries, setChartSeries] = useState([
//     {
//       name: 'Expenditure Type 1',
//       data: [0, 0, 0]
//     },
//     {
//       name: 'Expenditure Type 2',
//       data: [0, 0, 0]
//     },
//     {
//       name: 'Expenditure Type 3',
//       data: [0, 0, 0]
//     }
//   ]);

//   const fetchData = async () => {
//     try {
//       const monthsToFetch = getDisplayMonths().map(month => monthNames.indexOf(month) + 1);
//       const response = await axios.post(`${process.env.REACT_APP_API_URL}Dashbord-Expenditure-List`, {
//         months: monthsToFetch,
//         year: selectedYear
//       }, {
//         headers: {
//           'Content-Type': 'application/json',
//           'x-access-token': token
//         }
//       });

//       const data = response.data.data;

//       // Create a map to hold series data dynamically
//       const seriesDataMap = {};

//       data.forEach(item => {
//         const monthIndex = monthsToFetch.indexOf(item.month);
//         const expenditureTypeName = item.expenditure_type_name.trim();

//         // Initialize the expenditure type in the map if not already present
//         if (!seriesDataMap[expenditureTypeName]) {
//           seriesDataMap[expenditureTypeName] = Array(3).fill(0);
//         }

//         // Sum the total amounts for the expenditure type in the respective month
//         if (monthIndex !== -1) {
//           seriesDataMap[expenditureTypeName][monthIndex] += item.total_amount || 0;
//         }
//       });

//       // Convert the map into a format suitable for the chart
//       let chartSeriesData = Object.keys(seriesDataMap).map(key => ({
//         name: key,
//         data: seriesDataMap[key]
//       }));

//       // Sort chart series data based on the total amount across all months
//       chartSeriesData = chartSeriesData.sort((a, b) => {
//         const totalA = a.data.reduce((sum, val) => sum + val, 0);
//         const totalB = b.data.reduce((sum, val) => sum + val, 0);
//         return totalB - totalA; // Sort descending by total amount
//       });

//       setChartSeries(chartSeriesData);

//       setChartOptions((prevOptions) => ({
//         ...prevOptions,
//         xaxis: {
//           ...prevOptions.xaxis,
//           categories: getDisplayMonths()
//         }
//       }));

//     } catch (error) {
//       console.error('Error fetching chart data:', error);
//       setChartSeries([]);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [currentMonthIndex, selectedYear, token]);

//   const handleNext = () => {
//     if (currentMonthIndex < 11) {
//       setCurrentMonthIndex(currentMonthIndex + 1);
//     }
//   };
  
//   const handlePrevious = () => {
//     if (currentMonthIndex > 2) {
//       setCurrentMonthIndex(currentMonthIndex - 1);
//     }
//   };

//   return (
//     <Card className="flex-fill default-cover expenditure-chartt">
//       <Card.Header className="d-flex justify-content-between align-items-center">
//         <h5 className="card-title mb-0 text-white">Expenditure Overview</h5>
//         <div className="filters">
//           <label className='text-white' htmlFor="year">Year:</label>
//           <select
//             id="year"
//             value={selectedYear}
//             onChange={(e) => setSelectedYear(Number(e.target.value))}
//           >
//             {Array.from({ length: 5 }, (_, i) => today.getFullYear() - i).map(year => (
//               <option key={year} value={year}>
//                 {year}
//               </option>
//             ))}
//           </select>
//         </div>
//       </Card.Header>
//       <Card.Body className='padding_50'>
//         <div className="app">
//           <div className="chart-container position-relative">
//             <div className="mixed-chart" style={{ width: '100%' }}>
//               <Chart
//                 options={chartOptions}
//                 series={chartSeries}
//                 type="bar"
//                 height={410}
//               />
//             </div>
//             <div className="month-navigation">
//               <button onClick={handlePrevious} className='arrowback'><IoIosArrowBack /></button>
//               <button onClick={handleNext} className='arrowforward'><IoIosArrowForward /></button>
//             </div>
//           </div>
//         </div>
//       </Card.Body>
//     </Card>
//   );
// };

// export default ExpenditureChart;
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Card } from 'react-bootstrap';

const ExpenditureChart = () => {
  const today = new Date();
  const token = localStorage.getItem('token');

  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const [currentMonthIndex, setCurrentMonthIndex] = useState(today.getMonth());
  const [selectedYear, setSelectedYear] = useState(today.getFullYear());

  const getDisplayMonths = () => {
    let months = [];
    for (let i = 2; i >= 0; i--) {
      const monthIndex = (currentMonthIndex - i + 12) % 12;
      months.push(monthNames[monthIndex]);
    }
    return months;
  };

  const [chartOptions, setChartOptions] = useState({
    chart: {
      id: 'basic-bar',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: getDisplayMonths(),
    },
    colors: ['#008FFB', '#00E396', '#FEB019'], 

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '60%',
        endingShape: 'rounded'
      }
    },
    dataLabels: {
      enabled: false,  
      formatter: (val) => {
        return val.toLocaleString(); // Add commas to the data labels
      }
    },
    tooltip: {
      theme: 'dark', // Ensures the text is white by default
      y: {
        formatter: function (val) {
          return val.toLocaleString(); // Format tooltip values with commas
        }
      },
      style: {
        fontSize: '14px', // Customize the font size
        fontFamily: 'Helvetica, Arial, sans-serif'
      },
      fillSeriesColor: false, // Prevents tooltip text from taking color from the series
      marker: {
        show: false // Hide the marker in the tooltip
      },
      background: '#000', // Background color of tooltip
      borderColor: '#000', // Border color of tooltip
      textStyle: {
        color: '#000' // Text color of the tooltip
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['#ffffff']
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    grid: {
      padding: {
        left: 0,
        right: 0
      }
    }
  });

  const [chartSeries, setChartSeries] = useState([
    {
      name: 'Expenditure Type 1',
      data: [0, 0, 0]
    },
    {
      name: 'Expenditure Type 2',
      data: [0, 0, 0]
    },
    {
      name: 'Expenditure Type 3',
      data: [0, 0, 0]
    }
  ]);

  const fetchData = async () => {
    try {
      const monthsToFetch = getDisplayMonths().map(month => monthNames.indexOf(month) + 1);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}Dashbord-Expenditure-List`, {
        months: monthsToFetch,
        year: selectedYear
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token
        }
      });

      const data = response.data.data;

      // Create a map to hold series data dynamically
      const seriesDataMap = {};

      data.forEach(item => {
        const monthIndex = monthsToFetch.indexOf(item.month);
        const expenditureTypeName = item.expenditure_type_name.trim();

        // Initialize the expenditure type in the map if not already present
        if (!seriesDataMap[expenditureTypeName]) {
          seriesDataMap[expenditureTypeName] = Array(3).fill(0);
        }

        // Sum the total amounts for the expenditure type in the respective month
        if (monthIndex !== -1) {
          seriesDataMap[expenditureTypeName][monthIndex] += item.total_amount || 0;
        }
      });

      // Convert the map into a format suitable for the chart
      let chartSeriesData = Object.keys(seriesDataMap).map(key => ({
        name: key,
        data: seriesDataMap[key]
      }));

      // Sort chart series data based on the total amount across all months
      chartSeriesData = chartSeriesData.sort((a, b) => {
        const totalA = a.data.reduce((sum, val) => sum + val, 0);
        const totalB = b.data.reduce((sum, val) => sum + val, 0);
        return totalB - totalA; // Sort descending by total amount
      });

      setChartSeries(chartSeriesData);

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          categories: getDisplayMonths()
        }
      }));

    } catch (error) {
      console.error('Error fetching chart data:', error);
      setChartSeries([]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentMonthIndex, selectedYear, token]);

  const handleNext = () => {
    if (currentMonthIndex < 11) {
      setCurrentMonthIndex(currentMonthIndex + 1);
    }
  };
  
  const handlePrevious = () => {
    if (currentMonthIndex > 2) {
      setCurrentMonthIndex(currentMonthIndex - 1);
    }
  };

  return (
    <Card className="flex-fill default-cover expenditure-chartt">
      <Card.Header className="d-flex justify-content-between align-items-center">
        <h5 className="card-title mb-0 text-white">Expenditure Overview</h5>
        <div className="filters">
          <label className='text-white' htmlFor="year">Year:</label>
          <select
            id="year"
            value={selectedYear}
            onChange={(e) => setSelectedYear(Number(e.target.value))}
          >
            {Array.from({ length: 5 }, (_, i) => today.getFullYear() - i).map(year => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </Card.Header>
      <Card.Body className='padding_50'>
        <div className="app">
          <div className="chart-container position-relative">
            <div className="mixed-chart" style={{ width: '100%' }}>
              <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={410}
              />
            </div>
            <div className="month-navigation">
              <button onClick={handlePrevious} className='arrowback'><IoIosArrowBack /></button>
              <button onClick={handleNext} className='arrowforward'><IoIosArrowForward /></button>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ExpenditureChart;
