import React, { useState, useEffect, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import DataTable from './Stocktable';
import axios from 'axios';
import { Link } from 'react-router-dom';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const StockManagement = () => {
    const token = localStorage.getItem('token');
    const account_ladger_id = localStorage.getItem('account_ladger_id');
    const [loading, setLoading] = useState(false);
    const [data, setVenderlist] = useState([]);
    const [name, setVenderName] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [transactionType, setTransactionType] = useState('');

    useEffect(() => {
        fetchVendor();
    }, [token]);

    const fetchVendor = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}Account-ladger-list/${account_ladger_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-access-token': token,
                },
            });
            setVenderlist(response.data.data || []);
            setVenderName(response.data.data[0] || []);
        } catch (error) {
            console.error('Error fetching vendors:', error);
        }
    };

    // Filter Data by Date and Transaction Type
    const filteredData = useMemo(() => {
        let filtered = data;
        if (fromDate || toDate) {
            filtered = data.filter(item => {
                const itemDate = new Date(item.created_at).toISOString().split('T')[0];
                const fromCondition = fromDate ? itemDate >= fromDate : true;
                const toCondition = toDate ? itemDate <= toDate : true;
                return fromCondition && toCondition;
            });
        }

        if (transactionType) {
            filtered = filtered.filter(item => item.account_type === transactionType);
        }

        return filtered;
    }, [data, fromDate, toDate, transactionType]);

    // Total Debit
    const totalDebit = useMemo(() => {
        return filteredData.reduce((total, item) => {
            return item.type === 'withdraw' ? total + (parseFloat(item.amount) || 0) : total;
        }, 0);
    }, [filteredData]);

    // Total Credit
    const totalCredit = useMemo(() => {
        return filteredData.reduce((total, item) => {
            return item.type === 'deposit' ? total + (parseFloat(item.amount) || 0) : total;
        }, 0);
    }, [filteredData]);

    // Total Amount for Cash Transactions
    const totalCash = useMemo(() => {
        return filteredData.reduce((total, item) => {
            return item.account_type === 'Cash' ? total + (parseFloat(item.amount) || 0) : total;
        }, 0);
    }, [filteredData]);

    // Total Amount for Online Transactions
    const totalOnline = useMemo(() => {
        return filteredData.reduce((total, item) => {
            return item.account_type === 'Online' ? total + (parseFloat(item.amount) || 0) : total;
        }, 0);
    }, [filteredData]);

    const columns = useMemo(() => [
        {
            Header: 'Date',
            accessor: 'created_at',
            Cell: ({ value }) => {
                const date = new Date(value);
                return date.toLocaleDateString('en-GB'); // 'en-GB' gives DD-MM-YYYY format
            }
        },
        {
            Header: 'Transaction Name',
            accessor: '',
            Cell: ({ row: { original: { type, expenditure_type_name, account_to_name, customer_name, vendor_name } } }) => {
                if (type === 'withdraw') {
                    const values = [expenditure_type_name, customer_name, vendor_name, account_to_name].filter(Boolean);
                    return <span style={{ color: 'red' }}>{values.join(' || ')}</span>;
                } else if (type === 'deposit') {
                    const values = [customer_name, account_to_name].filter(Boolean);
                    return <span style={{ color: 'green' }}>{values.join(' || ')}</span>;
                } else {
                    return <span>No Data</span>;
                }
            },
        },
        {
            Header: 'Type',
            accessor: 'type',
        },
        {
            Header: 'Account Type',
            accessor: 'account_type',
        },
        {
            Header: 'Remark',
            accessor: 'remark',
        },
        {
            Header: 'Debit',
            id: 'dabit_amount',
            accessor: 'amount',
            Cell: ({ value, row: { original: { type } } }) => (
                type === 'withdraw' && value !== undefined ? (
                    <span style={{ color: 'red' }}>
                        {new Intl.NumberFormat().format(value)}
                    </span>
                ) : null
            ),
        },
        {
            Header: 'Credit',
            id: 'cradit_amount',
            accessor: 'amount',
            Cell: ({ value, row: { original: { type } } }) => (
                type === 'deposit' && value !== undefined ? (
                    <span style={{ color: 'green' }}>
                        {new Intl.NumberFormat().format(value)}
                    </span>
                ) : null
            ),
        },
    ], []);

    const downloadPDF = () => {
        const doc = new jsPDF();
        doc.text('Account Ledger Report', 14, 16);

        const tableColumn = ["Account Name", "Date", "Type", "Account Type", "Remark", "Transaction", "Debit", "Credit"];
        const tableRows = [];

        filteredData.forEach(item => {
            const rowData = [
                item.account_from_name,
                new Date(item.created_at).toLocaleDateString('en-GB'),
                item.type,
                item.account_type,
                item.remark || 'N/A',
                item.type === 'withdraw'
                    ? `${item.expenditure_type_name || ''} ${item.vendor_name || ''}  ${item.account_to_name || ''}`
                    : `${item.customer_name || ''}  ${item.account_to_name || ''}`,
                item.type === 'withdraw' ? new Intl.NumberFormat().format(item.amount) : '',
                item.type === 'deposit' ? new Intl.NumberFormat().format(item.amount) : '',
            ];
            tableRows.push(rowData);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 45,
        });
        doc.save('account_ledger_report.pdf');
    };
    const Debitdata = new Intl.NumberFormat().format(totalDebit);
    const Creditdata = new Intl.NumberFormat().format(totalCredit);

    return (
        <>
            <div className='d-flex justify-content-between align-items-center gap-2 bg-white p-3 mb-2'>
                <h5 className="mb-0">Account Ledger <span className='text-success fs-3'>{name.account_from_name}</span></h5>

                <div className='d-flex gap-2'>
                    <Form.Group className="mb-3">
                        <Form.Label className="custom-label">Date From</Form.Label>
                        <Form.Control
                            type="date"
                            value={fromDate}
                            onChange={e => setFromDate(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="custom-label">Date To</Form.Label>
                        <Form.Control
                            type="date"
                            value={toDate}
                            onChange={e => setToDate(e.target.value)}
                        />
                    </Form.Group>
                <div className="d-flex gap-2 align-items-center mt-2">
                    <div className='d-flex justify-content-between'>
                        <Button onClick={() => setTransactionType('Cash')} className="">Cash</Button>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Button onClick={() => setTransactionType('Online')} className="">Online</Button>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <Button onClick={downloadPDF} className="btn btn-secondary">Download PDF</Button>
                    </div>
                </div>
                </div>
                <div className='d-flex justify-content-between mt-2 gap-2'>
                    <Link to="/Account-list" className="btn btn-secondary">Back</Link>
                </div>
            </div>

            <div className='row bg-white m-0 border-0 p-3'>
                <DataTable columns={columns} data={filteredData} Debitdata={Debitdata} Creditdata={Creditdata} />
                {/* <h6 className='text-primary p-2 bg-light rounded'>
                    Total Debit: ₹ {new Intl.NumberFormat().format(totalDebit)}
                </h6>
                <h6 className='text-primary p-2 bg-light rounded'>
                    Total Credit: ₹ {new Intl.NumberFormat().format(totalCredit)}
                </h6> */}
                <h6 className='text-primary p-2 bg-light rounded'>
                    Total Cash: ₹ {new Intl.NumberFormat().format(totalCash)}
                </h6>
                <h6 className='text-primary p-2 bg-light rounded'>
                    Total Online: ₹ {new Intl.NumberFormat().format(totalOnline)}
                </h6>
            </div>
        </>
    );
};

export default StockManagement;
