import React, { useState, useEffect, useMemo } from 'react';
import { Button, Modal, Row, Col, Form, Card } from 'react-bootstrap';
import { MdAddCircle, MdDelete, MdHeight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import axios from 'axios';
import Select from 'react-select';
import Product from '../assets/img/product.png';
import DataTable from './Stocktable';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddstockUpdate from '../Stock/AddstockUpdate';
import Addproduct from '../Stock/Addstock';
import { useTable, useGlobalFilter, usePagination } from 'react-table';
import { useNavigate } from 'react-router-dom';


const StockManagement = () => {
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);
  const [data, setVendorList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [productlocation, setProductLocation] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();


  const [modalState, setModalState] = useState({
    addProductUpdate: false,
    addproduct: false,
  });

  useEffect(() => {
    if (!modalState.addProductUpdate || !modalState.addproduct) {
      fetchVendor();
    }
  }, [token, modalState.addProductUpdate, modalState.addproduct]);

  const ProductUpdate = (id) => {
    // setModalState({ ...modalState, addProductUpdate: true });
    localStorage.setItem('stock_update_id', id);
    navigate('/Add-Stock-Update');
  };

  const fetchVendor = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}Product-stock-list`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      setVendorList(response.data.data || []);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      toast.error('Failed to fetch vendors.');
    }
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}category-list`, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        setCategories(response.data.data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Failed to fetch categories.');
      }
    };
    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchProductLocation = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}product-stock-location-list`, {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        });
        setProductLocation(response.data.data || []);
      } catch (error) {
        console.error('Error fetching product locations:', error);
        toast.error('Failed to fetch product locations.');
      }
    };
    fetchProductLocation();
  }, [token]);

  const VenderDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      if (result.isConfirmed) {
        let url = `${process.env.REACT_APP_API_URL}Product-stoct-delete/${id}`;
        const config = {
          method: 'get',
          url: url,
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': token,
          },
        };
        const response = await axios(config);
        if (response.data.status === 1) {
          toast.success(response.data.message);
          fetchVendor();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const GlobalFilter = ({ filter, setFilter }) => {
    return (
      <input
        value={filter || ''}
        onChange={(e) => setFilter(e.target.value)}
        placeholder="Search..."
        className="form-control"
      />
    );
  };

  // Define columns
  const columns = useMemo(
    () => [
      {
        Header: 'Sr.No',
        accessor: 'Srno',
        Cell: (row) => row.row.index + 1,
      },
      {
        Header: 'Category',
        accessor: 'category_name',
      },
      {
        Header: 'Vendor Name',
        accessor: 'vendor_name',
      },
      {
        Header: 'Product Name',
        accessor: 'name',
      },
      {
        Header: 'Qty',
        accessor: 'pro_qty',
      },
      {
        Header: 'Gst',
        accessor: 'gst',
      },
      {
        Header: 'Rate',
        accessor: 'sale_man_amount',
        Cell: ({ value }) => (
          value !== undefined ? (
            new Intl.NumberFormat().format(value)
          ) : null
        ),
      },
      {
        Header: 'Amount',
        accessor: 'Saleprice',
        Cell: ({ row }) => {
          const qty = Number(row.original.pro_qty) || 0;
          const rate = Number(row.original.sale_man_amount) || 0;
          const gst = Number(row.original.gst) || 0;
          const amount = qty * rate * (1 + gst / 100);
          const formattedAmount = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
          return formattedAmount;
        },
      },
      {
        Header: 'Action',
        id: 'action',
        Cell: ({ row }) => {
          const item = row.original;
          return (
            <div className="d-flex justify-content-around">
              <Button
                className="p-2"
                onClick={() => ProductUpdate(item.id)}
              >
                <FaEdit />
              </Button>
              <Button
                className="p-2"
                onClick={() => VenderDelete(item.id)}
              >
                <MdDelete />
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const categoryOptions = categories.map((item) => ({
    value: item.id,
    label: item.name,
  }));


  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value); // Keep it as a string
  };

  const filteredOrderList = useMemo(() => {
    return data
      .filter(item =>
        // First filter by selectedStatus, if applicable
        !selectedStatus || item.product_stock_location_id === selectedStatus
      )
      .filter(item =>
        // Then filter by search input
        item.name.toLowerCase().includes(searchInput.toLowerCase())
      );
  }, [data, selectedStatus, searchInput]);


  const calculateTotals = (products) => {
    const totalQty = products.reduce((acc, item) => acc + parseInt(item.pro_qty, 10), 0);
    const totalAmt = products.reduce((acc, item) => {
      const qty = parseFloat(item.pro_qty) || 0;
      const rate = parseFloat(item.sale_man_amount) || 0;
      const gst = parseFloat(item.gst) || 0;

      return acc + (qty * rate * (1 + gst / 100));
    }, 0);

    setTotalQuantity(totalQty);
    setTotalAmount(totalAmt);
    // alert(totalAmt);
  };

  useEffect(() => {
    calculateTotals(filteredOrderList);
  }, [filteredOrderList]);

  const handleProductSelect = (selectedOption) => {
    if (selectedOption) {
      const selectedProductId = selectedOption.value;
      const filteredData = data.filter(item => item.id === selectedProductId);
      calculateTotals(filteredData);
    } else {
      calculateTotals(data); // Reset to show totals of all products
    }
  };

  return (
    <>
      <div>
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Manage Stock</h4>
              <h6>Manage Your Stock</h6>
            </div>
          </div>
          <div className="page-btn">
            <Link to="/addstock" className="btn btn-added d-flex gap-2">
              <MdAddCircle className="me-2" />Add Stock
            </Link>
          </div>
        </div>
        <Row>
          <Col md={12}>
            <Card style={{ height: 245 }}>
              <Card.Header>
                <div className="card-title">Stock Report</div>
              </Card.Header>
              <Card.Body>
                <Row className='d-flex justify-content-between'>
                  {/* <Col lg={5} sm={12} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Select</Form.Label>
                      <Select
                        options={categoryOptions}
                        isSearchable={true}
                        placeholder="Select"
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={4}>
                    <Form.Label>Search Product</Form.Label>
                    <input
                      type="text"
                      placeholder="Search..."
                      filter={searchInput}
                      setFilter={setSearchInput}
                      className="form-control"
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Col>
                  <Col lg={5}>
                    <div className="sales-cards">
                      <div className="card d-flex align-items-center justify-content-between default-cover mb-4">
                        <div>
                          <h6>Total Product</h6>
                          <h3>
                            <span>Total Quantity: {totalQuantity}</span>

                          </h3>
                          <span>Total Amount: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalAmount)}</span>
                        </div>
                        <img src={Product} alt="img" width={90} />
                      </div>
                    </div></Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col md={4}>
            <div className="sales-cards">
              <div className="card d-flex align-items-center justify-content-between default-cover mb-4">
                <div>
                  <h6>Total Product</h6>
                  <h3>
                  <span>Total Quantity: {totalQuantity}</span>
                 
                  </h3>
                  <span>Total Amount: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(totalAmount)}</span>
                </div>
                <img src={Product} alt="img" width={90} />
              </div>
            </div>
          </Col> */}
        </Row>

        <div className="justify-content-center processing">
          <div className="text-center">
            <Row>
              <Col md={12} xs={12} lg={12} className='duedrop'>
                <Form.Select aria-label="Default select example" onChange={handleStatusChange} value={selectedStatus} style={{ border: '1px solid #8080802' }}>
                  <option value="">Select Location</option>
                  {productlocation.map((vendor) => (
                    <option key={vendor.id} value={vendor.id}>
                      {vendor.product_stock_location_name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </div>
        </div>

        <Row className="mt-1">
          <Col>
            <DataTable
              columns={columns}
              data={filteredOrderList}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default StockManagement;
